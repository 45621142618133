import { Button, DatePicker, Form, Input, Select } from "antd"
import React, { useEffect } from "react"
import "./style.scss"
import moment from "moment"
import { navigate } from "gatsby"
import queryString from "query-string"
const Filter = ({ location }) => {
  const [form] = Form.useForm()

  const onFinish = (values) => {
    const query = queryString.parse(location.search)
    console.log(values)
    if (values?.start_date) {
      values.start_date = moment(values?.startDate).format("l")
    }
    if (values?.end_date) {
      values.end_date = moment(values?.endDate).format("l")
    }
    const newQueryStr = {
      ...query,
      ...{ pageSize: 10, pageCurrent: 1 },
      ...values,
    }

    navigate(
      `/order-management?${queryString.stringify(newQueryStr)}`,
      undefined,
      {
        shallow: false,
      }
    )
  }
  useEffect(() => {
    const query = queryString.parse(location.search)

    form.setFieldsValue({
      keyword: query?.keyword,
      status: query?.status,
      start_date: query?.start_date ? moment(query?.start_date) : null,
      end_date: query?.end_date ? moment(query?.end_date) : null,
    })
  }, [location])
  return (
    <Form className="customForm" form={form} onFinish={onFinish}>
      <div className="text-[#595959] text-sm font-notoSansJP tracking-[0.3px] mb-2">
        絞り込み条件
      </div>
      <div className="flex items-center justify-start">
        <div className="flex items-center">
          <Form.Item name="start_date">
            <DatePicker
              format={"YYYY年MM月"}
              picker="month"
              style={{ width: "181px" }}
              placeholder="2022年1月"
            />
          </Form.Item>
          <span className="mx-[5px] block w-1/5">から</span>
        </div>
        <div className="flex items-center">
          <Form.Item name="end_date">
            <DatePicker
              format={"YYYY年MM月"}
              picker="month"
              style={{ width: "181px" }}
              placeholder="2022年2月"
            />
          </Form.Item>
          <span className="mx-[5px] block w-1/5">まで</span>
        </div>
        <div className="!w-[150px]">
          <Form.Item name="status">
            <Select
              allowClear
              placeholder="ステータス"
              options={[
                {
                  value: "pending",
                  label: "確認待ち",
                },
                { value: "prepare", label: "発送準備中" },
                { value: "toShip", label: "発送完了" },

                {
                  status: "completed",
                  label: "発注処理完了",
                },

                {
                  value: "canceled",
                  label: "キャンセル",
                },
                {
                  value: "requires_action",
                  label: "返品・返金処理中",
                },
                {
                  value: "refunded",
                  label: "返品・返金処理完了",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className="!w-[402px] ml-[10px]">
          <Form.Item name="key">
            <Input placeholder="キーワードで探す" />
          </Form.Item>
        </div>
        <Button type="primary" htmlType="submit" className="!w-[141px] ml-5">
          検索
        </Button>
      </div>
    </Form>
  )
}

export default Filter
